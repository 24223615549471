import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, theme, ThemeProvider } from '@m/alchemy-ui';
import styled from 'styled-components';
import { getSingleText } from '../../../common/utils';

export const StyledDialogFooter = styled(Dialog.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const StyledDialogBody = styled(Dialog.Body)`
  margin: 0 0 24px 0;
`;

export default function RefreshConfirmationModal({
  isOpen,
  setShowRefreshConfirmationModal,
  onApproveProcess,
}) {
  
  const onClose = () => {
    setShowRefreshConfirmationModal(false);
  };
  const onApprove = () => {
    onApproveProcess();
    onClose();
  };
  const texts = getSingleText('app.pages.forms.refreshForm');
  const selectedFormsText = `${texts.selected} ${texts.forms}`;
  const title = `${texts.modal.header} ${selectedFormsText}?`;


  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Dialog isOpen={isOpen} onClose={onClose} hasCloseIcon={true}>
          <Dialog.Header>
            <p id="dialogTitle">{title}</p>
          </Dialog.Header>
          <StyledDialogBody>
            <p>
              {texts.modal.first_paragraph}
            </p>
            <br />
            <p>
              {texts.modal.second_paragraph}
            </p>
          </StyledDialogBody>
          <StyledDialogFooter>
            <Button pallete="interactiveNavigational" priority="tertiary" onClick={onClose}>{texts.modal.cancel}</Button>
            <Button onClick={onApprove}>{texts.modal.approve}</Button>
          </StyledDialogFooter>
        </Dialog>
      </React.Fragment>
    </ThemeProvider>
    
  );
}

RefreshConfirmationModal.propTypes = {
  onApproveProcess: PropTypes.func,
  setShowRefreshConfirmationModal: PropTypes.func,
  isOpen:PropTypes.bool,
  isMobile:PropTypes.bool,
};
