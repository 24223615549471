import styled from 'styled-components';
import { borderRadius, units } from '@m/alchemy-ui';
import { BLACK, WHITE, LIGHT_SEPARATOR, DARK_SEPARATOR , IOS_PRIMARY, ANDROID_PRIMARY, GRAY_BACKGROUND , DARK_ANDROID_PRIMARY , DARK_BACKGROUND } from '../../../../../../../common/colors';

export const StyledIOSAlertLivePreview = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  position: absolute;
  top: 193px;
  right: 66px;
  width: 270px;
  min-height: 234px;
  background-color: ${({ isDarkEnabled })=> isDarkEnabled ? DARK_BACKGROUND : GRAY_BACKGROUND };
  border-radius: 14px;
  text-align: center;
`;

export const StyledIOSAlertHeadline = styled.span`
  display: block;
  font-size: 17px;
  font-weight: 600;
  padding: 24px 16px 0 16px;
  color: ${({ isDarkEnabled })=> isDarkEnabled ? WHITE : BLACK };
`;

export const StyledIOSAlertText = styled.span`
  display: block;
  font-size: 13px;
  border-bottom : ${({ isDarkEnabled }) => isDarkEnabled ? `1px solid ${DARK_SEPARATOR}` : `1px solid ${LIGHT_SEPARATOR}`};
  padding: 2px 16px 16px 16px;
  color: ${({ isDarkEnabled })=> isDarkEnabled ? WHITE : BLACK };
`;

export const StyledIOSAlertLaterButton = styled.span`
  display: block;
  font-size: 17px;
  color: ${IOS_PRIMARY};
  font-weight: 600;
  border-bottom : ${({ isDarkEnabled }) => isDarkEnabled ? `1px solid ${DARK_SEPARATOR}` : `1px solid ${LIGHT_SEPARATOR}`};
  padding: ${units(1.5)} ${units(2)} ${units(1.5)} ${units(2)};
`;

export const StyledIOSAlertButton = styled.span`
  display: block;
  font-size: 17px;
  color: ${IOS_PRIMARY};
  padding: ${units(1.5)} ${units(2)} ${units(1.5)} ${units(2)};
  border-bottom : ${({ isSeparator, isDarkEnabled }) => isSeparator && isDarkEnabled ? `1px solid ${DARK_SEPARATOR}` : `1px solid ${LIGHT_SEPARATOR}`};
`;

export const StyledAndroidAlertLivePreview = styled.div`
  font-family: 'Roboto-Regular';
  position: absolute;
  top: 161px;
  right: 58px;
  width: 280px;
  min-height: 234px;
  background-color: ${({ isDarkEnabled })=> isDarkEnabled ? DARK_BACKGROUND : GRAY_BACKGROUND };
  border-radius: ${borderRadius('small')};
  text-align: left;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 24px 48px rgba(0, 0, 0, 0.2);
  padding-bottom: ${units(2)};
`;

export const StyledAndroidAlertHeadline = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 500;
  padding: ${units(3)} ${units(3)} ${units(1.75)} ${units(3)};
  color: ${({ isDarkEnabled })=> isDarkEnabled ? WHITE : BLACK };
`;

export const StyledAndroidAlertText = styled.span`
  display: block;
  font-size: 16px;
  padding: 0 ${units(3)} ${units(1)} ${units(3)};
  color: ${({ isDarkEnabled })=> isDarkEnabled ? WHITE : BLACK };
`;

export const StyledAndroidButton = styled.span`
    display: block;
    text-align: right;
    font-size: 14px;
    color: ${({ isDarkEnabled })=> isDarkEnabled ? DARK_ANDROID_PRIMARY : ANDROID_PRIMARY };
    padding: ${units(4)} ${units(3)} 0 ${units(3)};
`;