import React, { useContext } from 'react';
import { TriggerContext } from '../../../../..';
import { StyledIOSAlertLivePreview, StyledIOSAlertHeadline, StyledIOSAlertText,
  StyledIOSAlertLaterButton, StyledIOSAlertButton, StyledAndroidAlertHeadline,
  StyledAndroidAlertLivePreview, StyledAndroidAlertText, StyledAndroidButton } from './StyledAlertPreview';
import { getPropertyType } from '../../../../../../../../redux/store/store-helper';
import { PROPERTY_TYPES } from '../../../../../../../../common/constants';

const AlertPreview = ({ isDarkEnabled }) => {
  const { triggerState } = useContext(TriggerContext);
  const propertyType = getPropertyType();
  const {
    trigger: {
      invitationHeadline,
      invitationText,
      provideButtonText,
      laterButtonText,
      declineButtonText,
    } } = triggerState;
  
  const StyledAlertPreview = propertyType === PROPERTY_TYPES.IOS ? StyledIOSAlertLivePreview : StyledAndroidAlertLivePreview;
  const StyledHeadline = propertyType === PROPERTY_TYPES.IOS ? StyledIOSAlertHeadline : StyledAndroidAlertHeadline;
  const StyledAlertText = propertyType === PROPERTY_TYPES.IOS ? StyledIOSAlertText : StyledAndroidAlertText;
  const StyledAlertButton = propertyType === PROPERTY_TYPES.IOS ? StyledIOSAlertButton : StyledAndroidButton;
  const StyledLaterButton = propertyType === PROPERTY_TYPES.IOS ? StyledIOSAlertLaterButton : StyledAndroidButton;

  return (
    <StyledAlertPreview isDarkEnabled={isDarkEnabled}>
      <StyledHeadline isDarkEnabled={isDarkEnabled}>{invitationHeadline}</StyledHeadline>
      <StyledAlertText isDarkEnabled={isDarkEnabled}>{invitationText}</StyledAlertText>
      <StyledAlertButton>{provideButtonText}</StyledAlertButton>
      <StyledAlertButton>{declineButtonText}</StyledAlertButton>
      <StyledLaterButton isSeparator>{laterButtonText}</StyledLaterButton>
    </StyledAlertPreview>
  );
};

export default AlertPreview;