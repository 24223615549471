// region external
import React from 'react';
import _, { throttle } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
// endregion
// region md-components
import { bind } from '@mdigital/components/dist/decorators';
import DigTable from '@mdigital/components/dist/components/Table';
import DigToggle from '@mdigital/components/dist/components/Toggle';
import DigTooltip from '@mdigital/components/dist/components/Tooltip';
import ViewTemplate from '@mdigital/components/dist/components/ViewTemplate';
import Draggable from '@mdigital/components/dist/components/DragTooltip';
import DigLabel, { LABEL_TYPES } from '@mdigital/components/dist/components/Label';
import CircularProgressBar from '@mdigital/components/dist/components/CircularProgressBar';
import trashSvgIcon from '../../../assets/images/trash.svg';
import { highlightTextByQuery } from '@mdigital/components/dist/utils/common';
import { DOCUMENT_EVENTS, JOB_TYPES, SORT_ORDER, USER_ROLES } from '../../../common/enums';
// endregion
import FormViewActionBar from './FormViewActionBar';
import {
  FORM_DRAFT_LIST_VIEW_COLUMNS,
  FORM_LIST_VIEW_COLUMNS,
  StyledFormViewContainerComponent
} from './StyledFormViewContainer';
import DigFormCardCollection from '../DigFormCardCollection';
import { COLORS } from '../../../common/colors';
import { getNgService, getSingleText, getTriggerTypeLabel } from '../../../common/utils';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getCount, getFormIds, getPaginationSortedCatgories, selectedFormsSelector } from './selectors';
import newFormIcon from '../../../assets/images/add.svg';
import { checkProvision, getFolderById } from '../../../redux/store/store-helper';
import * as formActions from '../../../redux/actions/form.actions';
import * as refreshActions from '../../../redux/actions/refresh.actions';
import * as folderActions from '../../../redux/actions/folder.actions';
import DeleteSvg from '../../assets/actions/delete.svg';
import DuplicateSvg from '../../assets/actions/duplicate.svg';
import EditSvg from '../../assets/actions/edit.svg';
import LockSvg from '../../assets/actions/lock.svg';
import PreviewSvg from '../../assets/actions/preview.svg';
import TargetingSvg from '../../assets/actions/targeting.svg';
import TranslationSvg from '../../assets/actions/translation.svg';
import PreloadIcon from '../../assets/General/PreloadIcon.svg';
import DiscardDraftIcon from '../../assets/actions/dicard_draft.svg';
import PublishDraftIcon from '../../assets/actions/publish_draft.svg';
import UnpublishIcon from '../../assets/actions/unpublish.svg';
import ExportIcon from '../../assets/actions/export.svg';
import { fetchGenericRuleByFormId } from '../../../redux/actions/rule-engine.actions';
import CustomNoticeModalContainer from '../../modals/CustomNotice/CustomNoticeModalContainer';
import darkModeLabelIcon from '../../../assets/images/darkModeLabel.svg';
import RefreshConfirmationModal from './RefreshConfirmationModal';
import PublishStateLabel from '../PublishStateLabel/PublishStateLabel';

let IS_LIST_FOLDER_ENABLED, WEBSITE_PROPERTY = 'website';
const MAX_ACTIONS_IN_ROW = 3;
const UPDATED_DATE_FORMAT = 'MMMM DD, YYYY';
const PUBLISHED_DATE_FORMAT = 'MMM DD, YYYY';
const VIEW_TYPES = [ FormViewTypes.GRID, FormViewTypes.LIST];
const MIN_CHARACTERS_TO_FILTER_BY = 2;
const COLOR_SUFFIX = '_LABEL';
const TooltipStyle = {
  display: 'inline-block',
  width: '100%',
};
const DEBOUNCE_LOAD_CHUNKS_TIME_MS = 100;
const MAX_CELL_CHARACTERS = 37; // max characters before adding a tooltip
const INITIAL_DRAG_STATE = { isShow: false };
const actionTypeToSvg = {
  delete: DeleteSvg,
  duplicate: DuplicateSvg,
  edit: EditSvg,
  lock: LockSvg,
  preview: PreviewSvg,
  targeting: TargetingSvg,
  localization: TranslationSvg,
  publishDraft: PublishDraftIcon,
  discardDraft: DiscardDraftIcon,
  unpublish: UnpublishIcon,
  export: ExportIcon,
};
const CUSTOM_NOTICE_PROVISION = '21dcr3_MPC3779_enable_targetingNotice';
const ALLOWED_CUSTOM_NOTTICE_TRIGGERS = ['.InvitationContract','.EmbeddedContract'];

export class FormViewContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nextChunk: 0,
      draggableProps: INITIAL_DRAG_STATE,
      shouldShowSpinner:false,
      showRefreshConfirmationModal:false,
    };
    this.formsWrapperRef = React.createRef();
    if(this.props.isFormFoldersEnabled){
      this.props.setFormViewType(FormViewTypes.LIST);
    }
    this.isSurveyDraftEnabled = this.props.isFormFoldersEnabled && checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS);
    
    this.onColumnHeaderClicked = this.onColumnHeaderClicked.bind(this);
    this.getNewSortOrder = this.getNewSortOrder.bind(this);
    this.onViewTypeChange = this.onViewTypeChange.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
  }

  @bind
  selectedFolderHasNotChanged(prevProps){
    return !prevProps || !IS_LIST_FOLDER_ENABLED || (prevProps.selectedFolder && prevProps.selectedFolder.id == this.props.selectedFolder.id);
  }

  @bind
  shouldStopSpinner(prevProps){
    return  this.props.spinner.isAppSpinnerActive() && !this.state.shouldShowSpinner && this.isFormsFetched() && this.selectedFolderHasNotChanged(prevProps);
  }

  isMobileProperty(){
    return this.props.profile.propertyType !== WEBSITE_PROPERTY;
  }
  isWebsiteProperty(){
    return this.props.profile.propertyType === WEBSITE_PROPERTY;
  }
  isCustomNoticeEnabled(form){
    const { published, triggers } = form;
    const triggerType = triggers && triggers[0] && triggers[0].triggerType;
    const formAllowed = !published && ALLOWED_CUSTOM_NOTTICE_TRIGGERS.includes(triggerType);
    return formAllowed && checkProvision(CUSTOM_NOTICE_PROVISION) && this.isWebsiteProperty();
  }
  @bind
  isFormsFetched(){
    const { forms, count } = this.props;
    return Boolean(forms.undeletedForms) && (!IS_LIST_FOLDER_ENABLED || forms.undeletedForms.length == count);
  }

  componentDidMount() {
    this.props.spinner.startSpin();
    IS_LIST_FOLDER_ENABLED = checkProvision(DigProvisions.FORM_FOLDERS);
    !this.props.isFormFoldersEnabled && this.props.getUndeletedForms();
    this.formsWrapperRef.current.addEventListener(DOCUMENT_EVENTS.SCROLL, throttle(this.loadFormsChunk, DEBOUNCE_LOAD_CHUNKS_TIME_MS), false);
    window.document.addEventListener(DOCUMENT_EVENTS.MOUSE_UP, this.handleDrop);
    if(this.isNeedToSetDefaultSort()){
      this.props.setSortOptions('formChangeTime',SORT_ORDER.DESC);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paginatedForms !== this.props.paginatedForms) {
      this.resetState();
    }
    if( this.props.isFormFoldersEnabled &&
      this.isSelectedFolderChanged({
        prevFolder: prevProps.folders.selectedFolder,
        currFolder:this.props.folders.selectedFolder,
        prevForm: prevProps.undeletedForms[0],
        currForm: this.props.undeletedForms[0] })) {
      this.props.spinner.startSpin();
      this.resetState();
      return;
    }
    if(this.shouldStopSpinner(prevProps)){
      setTimeout(() => this.props.spinner.stopSpin(),0);
    }
  }

  resetState = () => {
    this.setState({
      nextChunk: 0,
    }, () => {
      const element = getNestedProperty(this, 'formsWrapperRef.current');
      if (typeof element.scrollTo === 'function') {
        element.scrollTo(0,0);
      } else if (element instanceof HTMLElement) {
        // edge doesn't support  scrollTo, know issue: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15534521/
        element.scrollTop = 0;
      }
    });
  }

  @bind
  isSelectedFolderChanged({ prevFolder, currFolder, prevForm = {}, currForm = {} }) {
    return prevFolder && currFolder && (prevFolder.id !== currFolder.id || prevForm.folderId !== currForm.folderId);
  }

  isNeedToSetDefaultSort(){
    return (this.props.viewType === FormViewTypes.LIST || IS_LIST_FOLDER_ENABLED) && !this.props.sortBy;
  }

  componentWillUnmount() {
    this.props.spinner.stopSpin();
    window.document.removeEventListener(DOCUMENT_EVENTS.MOUSE_UP, this.handleDrop);
    this.props.filterQuery(null);
    this.props.uncheckAllForms();
  }

  @bind
  loadFormsChunk() {
    if(this.shouldLoadFormsChunk()){
      const nextChunk = this.state.nextChunk + 1;
      this.setState({ nextChunk : nextChunk });
    }
  }

  onViewTypeChange({ viewType }) {
    if(!this.props.sortBy && viewType === FormViewTypes.LIST){
      this.props.setSortOptions('formChangeTime',SORT_ORDER.DESC);
    }
    this.props.setFormViewType(viewType);
    this.resetState();
  }

  @bind
  shouldLoadFormsChunk(){
    const nextChunk = this.state.nextChunk + 1;
    const scrollTop = (this.formsWrapperRef.current && this.formsWrapperRef.current.scrollTop) || document.body.scrollTop;
    const scrollHeight = (this.formsWrapperRef.current && this.formsWrapperRef.current.scrollHeight) || document.body.scrollHeight;
    const clientHeight = this.formsWrapperRef.current.clientHeight || window.innerHeight;
    return Math.ceil(scrollTop + clientHeight) >= scrollHeight && this.props.paginatedForms[nextChunk];
  }

  @bind
  onSearch(searchQuery = '') {
    const query = searchQuery.length >= MIN_CHARACTERS_TO_FILTER_BY ? searchQuery : '';
    this.props.filterQuery(query);
  }

  @bind
  onRefresh(refreshConfig) {
    this.props.startRefreshPolling(refreshConfig.allSelectedForms, refreshConfig.areAllItemsChecked);
    getNgService(($rootScope) => $rootScope.closeAlert(), true);
  }

  getDragFormsText(rowId){
    const isDragWithoutMark = Object.keys(this.props.checkedForms).length === 0;
    const checkedFormsIds = isDragWithoutMark ? [rowId] : Object.keys(this.props.checkedForms);
    const checkedFormsCount = checkedFormsIds.length;
    const filteredFormsCount = checkedFormsCount 
      ? this.filterUnlockedForms(checkedFormsIds).length 
      : 0;
    const numberToDisplay = checkedFormsCount
      ? filteredFormsCount
      : this.isFormIdLocked(rowId) ? 0 : 1;
    const conditions = [
      {
        condition: filteredFormsCount === 0,
        res: 'Locked forms can not be moved!',
      },
      {
        condition: filteredFormsCount !== checkedFormsCount,
        res: `Move ${numberToDisplay} unlocked form${numberToDisplay > 1 ? 's' : ''}`,
      },
      {
        condition: true,
        res: `Move ${numberToDisplay} form${numberToDisplay > 1 ? 's' : ''}`,
      }
    ];
    return conditions.find((cond) => cond.condition).res;
  }

  filterLockForms = (forms) => this.props.propertyRole >= USER_ROLES.ROLE_USER_GROUP_MANAGER ? forms : forms.filter((form) => !form.isLocked);

  checkBoxSettingsBuilder = () => {
    const allSelectedForms = Object.keys(this.props.checkedForms);
    const undeletedForms =  this.isSurveyDraftEnabled ? this.filterPublishedAndNewDrafts(this.props.undeletedForms) : this.props.undeletedForms;
    const areAllItemsChecked = allSelectedForms.length > 0 && allSelectedForms.length === this.filterLockForms(undeletedForms).length;
    const isPartiallyChecked = allSelectedForms.length > 0 && !areAllItemsChecked;
    const settings = {
      checkItem: (formId) => this.props.checkForm(formId),
      uncheckItem: (formId) => this.props.uncheckForm(formId),
      isItemChecked: (formId) => !!this.props.checkedForms[formId],
      checkAllItems: () => this.props.checkAllForms(this.props.formIds, this.props.propertyRole),
      uncheckAllItems: () => this.props.uncheckAllForms(this.props.formIds),
      areAllItemsChecked,
      isPartiallyChecked,
      isFiltered: this.props.searchQuery,
      isCheckboxDisableMethod: (row) => this.isCheckboxDisable(row),
    };
    return settings;
  };

  onColumnHeaderClicked(column){
    const currentSortOrder = this.getNewSortOrder(column);
    this.props.setSortOptions(column.sortType, currentSortOrder);
  }

  getNewSortOrder(column){
    if(column.sortType === this.props.sortBy){
      return this.props.sortOrder === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
    } else{
      return SORT_ORDER.DESC;
    }
  }
  isCheckboxDisable = (...args) => {
    const [row] = args;
    return row.isLocked && this.props.propertyRole < UserRoles.ROLE_USER_GROUP_MANAGER;
  };

  isFormIdLocked = (id) =>
    this.props.formIds.some((form) =>
      +form.formId === +id && form.isLocked
    );

  handleDragStart = (event, rowId) => {
    this.setState({
      draggableProps: {
        label: this.getDragFormsText(rowId),
        isShow: true,
        initialPositionX: event.clientX,
        initialPositionY: event.clientY,
        currentDraggableFormId: rowId,
      },
    });
    document.body.classList.add('drag');
  };

  getLoadedChunks() {
    const forms = [];
    const { paginatedForms } = this.props;

    for (let i = 0; i <= this.state.nextChunk; i++) {
      const page = paginatedForms[i];
      if (page) {
        forms.push(...page);
      }
    }

    return forms;
  }

  filterPublishedAndNewDrafts(forms = []) {
    return forms.filter((form) => form.published || form.isDraft && (!form.formId || !this.isFormPublished(forms, form.formId)));
  }
  isFormPublished(forms, formId) {  
    const connectedForm = forms.find((form) => form.id === formId);
    return connectedForm && connectedForm.published;
  }
  updateDateOfDraft(publishedForm) {
    const draft = this.props.undeletedForms.find((form) => form.id === publishedForm.formDraftId);
    const objectToShow = draft && draft.formChangeTime ? draft : publishedForm;
    return moment(objectToShow['formChangeTime']).format(UPDATED_DATE_FORMAT);
  }
  getIsDraftLocked(formDraftId) {
    const { isLocked } = this.props.undeletedForms.find((form) => form.id === formDraftId);
    return !!isLocked;
  }
  renderContentByView() {
    let forms = this.getLoadedChunks();

    switch (this.props.viewType) {
      case FormViewTypes.GRID:
        return this.renderFormCardCollection(forms);

      case FormViewTypes.LIST:
        const columns = this.isSurveyDraftEnabled ? FORM_DRAFT_LIST_VIEW_COLUMNS : FORM_LIST_VIEW_COLUMNS;
        if (this.isSurveyDraftEnabled) {
          forms = this.filterPublishedAndNewDrafts(forms);
        }

        return (
          <DigTable digRows={forms.map(this.renderRows, this)}
            dataAut="forms-list-wrapper"
            onColumnHeaderClicked={this.onColumnHeaderClicked}
            digColumns={columns.map(renderColumns)}
            checkboxSettings = {this.props.isFormFoldersEnabled ? this.checkBoxSettingsBuilder.bind(this) : null}
            isDragEnabled={this.props.isFormFoldersEnabled} isCheckboxDisable = {this.isCheckboxDisable.bind(this)}
            onDrag={this.handleDragStart}/>
        );
      default:
        break;
    }
  }

  filterUnlockedForms = (formsToMove) => {
    const filteredFormIds = formsToMove.filter((id) => {
      id = parseInt(id);
      return this.props.formIds.some((form) => {
        return form.formId === id && !form.isLocked;
      });
    });
    return filteredFormIds;
  }

  handleDrop = (e) => {
    // Only in drag mode
    if (this.state.draggableProps.isShow) {
      const elementBelow = document.elementFromPoint(e.clientX, e.clientY);
      const dropFolderId = elementBelow.getAttribute('list-item-id');
      let formsToMove;
      const { checkedForms } = this.props;
      if(!checkedForms || !Object.keys(checkedForms).length){
        formsToMove = [this.state.draggableProps.currentDraggableFormId];
      } else{
        formsToMove = Object.keys(checkedForms);
      }
      formsToMove = this.filterUnlockedForms(formsToMove);
      const newDraggableProps = Object.assign(this.state.draggableProps);
      newDraggableProps.currentDraggableFormId = null;
      this.setState({ draggableProps:newDraggableProps });
      if (this.isValidDrop(dropFolderId, formsToMove)) {
        this.props.spinner.startSpin();
        this.setState({ shouldShowSpinner: true });
        this.props.moveFormsToFolder(dropFolderId, formsToMove).then(() => {
          this.setState({ shouldShowSpinner: false });
          this.props.spinner.stopSpin();
        });
      }
      document.body.classList.remove('drag');
      this.setState({ draggableProps: INITIAL_DRAG_STATE });
    }
  };

  isValidDrop(dropFolderId, formsToMove) {
    const { selectedFolder } = this.props;
    const dropFolder = getFolderById(dropFolderId);
    return dropFolderId && Number(dropFolderId) !== selectedFolder.id && formsToMove.length > 0 && !isFolderInDeleteStatus(dropFolder);
  }


  renderRows(form) {
    const { id, name, isLocked, published, formChangeTime, triggers, customHTMLEnabled,formId, isDraft, formDraftId } = form;
    const updatedDate = moment(formChangeTime).format(UPDATED_DATE_FORMAT);
    const publishUnpublishClick = () => this.handlePublishUnPublish(form);
    const actions = this.props.actions(form).map((action) => ({ ...action, iconSvg: actionTypeToSvg[action.type] }));
    const isPublishedWithDraftForm = this.isSurveyDraftEnabled && !isDraft && formDraftId;
    const isFormLocked = isPublishedWithDraftForm ? this.getIsDraftLocked(formDraftId) : isLocked;
    const rows = {
      id,
      isLocked: isFormLocked,
      maxActionsInRow: MAX_ACTIONS_IN_ROW,
      isShowLockIcon: false,
      values: this.isSurveyDraftEnabled ? [
        { value: this.getLabel(name) },
        { value: renderTypeCellContent(triggers, isLocked, this.props.propertyRole) },
        { value: isDraft ? this.getLabel(formId) : this.getLabel(id) },
        { value: published && !!formDraftId ? this.updateDateOfDraft(form) : updatedDate },
        { value: renderStatusLabel(form) },
        { value: isFormLocked && renderLockLabel(this.props.propertyRole) },
        { value: customHTMLEnabled && renderCustomHtmlLabel() }
      ] : [
        { value: isLocked && renderLockLabel(this.props.propertyRole) },
        { value: renderTypeCellContent(triggers, isLocked, this.props.propertyRole) },
        { value: this.getLabel(name) },
        { value: updatedDate },
        { value: this.getLabel(id) },
        { value: <DigToggle switchState={published} onToggle={publishUnpublishClick} /> }
      ],
      actions: _.sortByOrder(actions, 'order'),
      rowObject: form,
      cellMaxCharacters: MAX_CELL_CHARACTERS,
      autLabel: name,
    };
    return rows;
  }

  renderFormCardCollection(forms = []) {
    const cards = forms.map(this.populateCardWithMissingProperties, this);
    return (
      <div className='container kpl-form-container'>
        <DigFormCardCollection
          cards={cards}
          onSwitchToggle={this.handlePublishUnPublish} />
      </div>
    );
  }

  getLabel(text) {
    return this.props.searchQuery ? { tooltip: text, label: highlightTextByQuery(text, this.props.searchQuery) } : text;
  }

  populateCardWithMissingProperties(form) {
    const { preloadedFormId } = this.props.forms;
    const card = this.props.labels(form);
    if (preloadedFormId && preloadedFormId === card.id) {
      const markedLabel = card.labels.find((label) => label.type !== LABEL_TYPES.ICON);
      if (markedLabel) {
        markedLabel.svg = PreloadIcon;
        markedLabel.type = LABEL_TYPES.SVG;
      }
    }

    card.labels && card.labels.map((label)=>{
      if (label.type === 'icon'){
        label.type = LABEL_TYPES.ICON;
      }
      if(label.type === 'darkMode'){
        label.type = LABEL_TYPES.SVG;
        label.svg = darkModeLabelIcon;
      }
    });

    return Object.assign(card, {
      actionPanelCollection: this.props.actions(form),
      highlightText: highlightTextByQuery(form.name, this.props.searchQuery),
      highlightFormId: highlightTextByQuery(form.id, this.props.searchQuery),
    });
  }

  handleNoForms() {
    return this.props.isFormFoldersEnabled ? this.renderViewTemplate(this.getEmptyFolderConfig()) : renderNoFormsMessage();
  }

  getDeletingFolderConfig() {
    const { title, subTitle } = getSingleText('app.pages.forms.folders.deleteFolder');
    const innerSvgSettings = {
      src: trashSvgIcon,
      width: 30,
      height:40,
      positionX: 52,
      positionY: 48,
    };

    return {
      props: {
        imageSettings: {
          src: <CircularProgressBar svgIcon={trashSvgIcon} size={135} percentage={getPercentage(this.props.selectedFolder)} imageSettings={innerSvgSettings}/>,
          type: 'component',
        },
        title,
        subTitle,
        autLabel: 'delete-folder-view',
        viewWidth: '405px',
      },
      className: 'delete-folder-container',
    };
  }

  getEmptyFolderConfig = () => {
    const { title, subTitle, newFormAction } = getSingleText('app.pages.forms.folders.emptyFolder');
    return {
      props: {
        imageSettings:{ src: '../../../assets/images/emptyFolder.svg', style: { width:135, height:105 } },
        title,
        subTitle,
        actions: [{
          label: newFormAction,
          autLabel: 'addNewForm',
          onClick: this.props.createForm,
          iconSettings: { icon: newFormIcon, color: COLORS.GREEN, width:11, height:13 },
          style: { color: COLORS.GREEN },
        }],
        autLabel: 'empty-folder-view',
      },
      className: 'empty-folder-container',
    };
  };

  renderViewTemplate({ props, className }) {
    return <div className={className}><ViewTemplate viewWidth="370px" viewHeight="350px" {...props}/></div>;
  }

  renderContent() {
    if (isFolderInDeleteStatus(this.props.selectedFolder)) {
      // show delete folder page whether it has forms or not
      return this.renderViewTemplate(this.getDeletingFolderConfig());
    } else if (!this.props.undeletedForms.length) {
      // show empty folder page or no forms message
      return this.handleNoForms();
    } else {
      // show forms
      return this.renderContentByView();
    }
  }

  handleCloseCustomNoticeModal = () => {
    this.props.setCustomNoticeModalState({});
  }

  handlePublishUnPublish =  (form) => {
    if(this.isCustomNoticeEnabled(form)){
      this.props.fetchFormGenericRule(form.id)
        .then((genericRule) => {
          genericRule ? this.props.publishUnpublish(form) : this.props.setCustomNoticeModalState({ isShown:true });
        });
    }else{
      this.props.publishUnpublish(form);
    }
  }
  
  isRefreshButtonDisabled = (selectedCount) => {
    return this.props.isPollingFormsRefresh || !this.props.undeletedForms.length || !selectedCount;
  }
  
  getRefreshConfig = () => {
    const selectedCount = Object.keys(this.props.checkedForms).length;
    const allSelectedForms = Object.keys(this.props.checkedForms);
    return {
      isWebsiteProperty: this.isWebsiteProperty(),
      selectedCount,
      isPollingFormsRefresh: this.props.forms && this.props.forms.isPollingFormsRefresh,
      isDisableRefreshForm: this.isRefreshButtonDisabled(selectedCount),
      setShowRefreshConfirmationModal: (state) => this.setState({ showRefreshConfirmationModal: state }),
      allSelectedForms,
      areAllItemsChecked: allSelectedForms.length > 0 && allSelectedForms.length === this.props.undeletedForms.length,
    };
  }

  isFormRefreshVisible = () => {
    return checkProvision(DigProvisions.REFRESH_CONFIG_BUTTON) &&
      this.props.propertyRole >= USER_ROLES.ROLE_ADMIN &&
      (this.isMobileProperty() || this.props.isFormFoldersEnabled);
  };

  render() {
    const actionBarTitle = this.props.isFormFoldersEnabled && this.props.selectedFolder ? this.props.selectedFolder.name : undefined;
    const formsCount = this.props.isFormFoldersEnabled ? null : `(${this.props.count})`;
    const isFolderInDeletion = isFolderInDeleteStatus(this.props.selectedFolder);
    const isFormRefreshVisible = this.isFormRefreshVisible();
    const refreshConfig = isFormRefreshVisible ? this.getRefreshConfig() : {};
    const customProps = { isSurveyDraftEnabled: this.isSurveyDraftEnabled };

    return (
      <StyledFormViewContainerComponent {...this.props} {...customProps}>
        <CustomNoticeModalContainer handleCloseModal={this.handleCloseCustomNoticeModal} isShown={getNestedProperty(this,'props.customNoticeModalState.isShown')} />
        
        {isFormRefreshVisible && (<RefreshConfirmationModal
          setShowRefreshConfirmationModal={refreshConfig.setShowRefreshConfirmationModal}
          isOpen={getNestedProperty(this, 'state.showRefreshConfirmationModal')}
          onApproveProcess={() => this.onRefresh(refreshConfig)}

        />)}
        
        <FormViewActionBar
          isFormFoldersEnabled={this.props.isFormFoldersEnabled}
          title={actionBarTitle}
          onCreate={this.props.createForm}
          viewType={getNextViewType(this.props.viewType)}
          onViewChange={this.onViewTypeChange}
          onSearch={this.onSearch}
          refreshConfig={refreshConfig}
          isFormRefreshVisible={isFormRefreshVisible}
          counter={formsCount}
          isDisableCreateForm={isFolderInDeletion}
          isHideSearch={isFolderInDeletion}/>
        <div className="view-form-wrapper" ref={this.formsWrapperRef}>
          {
            this.renderContent()
          }
        </div>
        <Draggable { ...this.state.draggableProps }/>
      </StyledFormViewContainerComponent>
    );
  }
}

export function getPercentage(folder) {
  const { totalItems, completedItems } = folder.actionStatus;
  const percentage = (completedItems / totalItems) * 100;
  return isNaN(percentage) ? 0 : percentage;
}

export function isFolderInDeleteStatus(folder) {
  return KAMPYLE_UTILS.getNestedPropertyValue(folder, 'actionStatus.actionType') === JOB_TYPES.DELETE;
}

function renderNoFormsMessage() {
  return (
    <div className='no-forms-message'>
      <h1>{getSingleText('app.pages.forms.noForms')}</h1>
    </div>
  );
}

function renderTypeCellContent(triggers) {
  return (
    <span className='column-type'>
      <DigLabel {...getTriggerLabelProps(triggers)} />
    </span>
  );
}

function getTriggerLabelProps(triggers) {
  const content = getTriggerLabel(triggers);
  return {
    digClassName: 'dig-trigger-type-label',
    content,
    color: COLORS[content.toUpperCase() + COLOR_SUFFIX],
  };
}

function getTriggerLabel(triggers) {
  const triggerLabel = KAMPYLE_UTILS.getNestedPropertyValue(triggers, '0.triggerType') || '';
  return getTriggerTypeLabel(triggerLabel);
}

function renderColumns({ label, autLabel, isDraggable, sortType,icon, id }) {
  return { label, autLabel, isDraggable, sortType, icon, id };
}

function getDigBadge({ type, content }) {
  return <DigLabel digClassName='dig-badge' textColor={COLORS.WHITE} content={content} type={type} color={COLORS.BADGE_LABEL}/>;
}

function renderLockLabel(role) {
  const getDigCell = () => getDigBadge( {
    type: LABEL_TYPES.ICON,
    content: 'lock',
  });
  return <DigTooltip name="lock" digTooltip= {getLockedFormTooltipByRole(role)} digStyle={TooltipStyle} digElement={getDigCell}/>;
}

function renderCustomHtmlLabel() {
  const getDigCell = () => <DigLabel digClassName='dig-badge' textColor={COLORS.WHITE} content="HTML" type={LABEL_TYPES.TEXT} color={COLORS.BADGE_LABEL}/>;
  return <DigTooltip name="chtml" digTooltip={getSingleText('app.pages.forms.tooltips.customHTMLEnabled')} digElement={getDigCell}/>;
}

function renderStatusLabel({ formPublishTimestamp, published, formDraftId }) {
  const isPublishedWithRelatedDraft = published && !!formDraftId;
  const isPublishedLabel = published || isPublishedWithRelatedDraft;

  const inlineBlockStyle = { display: 'inline-block', 'verticalAlign': 'middle' };
  const getStatus = () => {
    const publishedDate = formPublishTimestamp ? getSingleText('app.pages.forms.tooltips.publishOn') + moment(formPublishTimestamp).format(PUBLISHED_DATE_FORMAT) : '';
    return <PublishStateLabel isPublished={isPublishedLabel} digTooltip={publishedDate} />;
  };
  const getDigCell = () => {
    if(isPublishedWithRelatedDraft) {
      const getDraftLabel = () => <div className="dig-point" style={{ margin: '4px 10px' }} />;
      const getDraft = () =><DigTooltip name="draft" digTooltip={getSingleText('app.pages.forms.tooltips.formDraft')} digStyle={inlineBlockStyle} digElement={getDraftLabel}/>;
      return <React.Fragment>
        {getStatus()}
        {getDraft()}
      </React.Fragment>;
    }
    return getStatus();
  };
  return getDigCell();
}

function getNextViewType(currentViewType) {
  const currentIndex = VIEW_TYPES.findIndex( (view) => view === currentViewType);
  const nextIndex = currentIndex === (VIEW_TYPES.length - 1) ? 0 : currentIndex + 1;
  return VIEW_TYPES[nextIndex];
}

function getLockedFormTooltipByRole(role) {
  const textPath = role >= UserRoles.ROLE_USER_GROUP_MANAGER ? 'app.pages.forms.tooltips.lockToolTipGroupManagers' : 'app.pages.forms.tooltips.lockToolTip';
  return getSingleText(textPath);
}

function mapDispatchToProps(dispatch) {
  return {
    setFormViewType: (viewType) => dispatch(formActions.setFormViewType(viewType)),
    filterQuery: (searchString) => dispatch(formActions.filterByQueryString(searchString)),
    checkForm: (formId) => dispatch(formActions.checkForm(formId)),
    uncheckForm: (formId) => dispatch(formActions.uncheckForm(formId)),
    checkAllForms: (formIds, propertyRole) => dispatch(formActions.checkAllForms(formIds, propertyRole)),
    uncheckAllForms: (formIds) => dispatch(formActions.uncheckAllForms(formIds)),
    moveFormsToFolder: (folderId, formsIds) => new Promise((resolve) =>  dispatch(folderActions.moveFormsToFolder(folderId, formsIds, resolve))),
    setSortOptions: (sortBy,sortOrder) => dispatch(formActions.setSortOptions(sortBy,sortOrder)),
    getUndeletedForms: () => dispatch(formActions.getUndeletedForms()),
    setCustomNoticeModalState:(modalState) => dispatch(formActions.setCustomNoticeModalState(modalState)),
    fetchFormGenericRule:(formId) => new Promise((resolve,reject) => dispatch(fetchGenericRuleByFormId(formId,resolve,reject))),
    startRefreshPolling: (allSelectedForms, areAllItemsChecked) => dispatch(refreshActions.startRefreshPolling(allSelectedForms, areAllItemsChecked)), 
    stopRefreshPolling: () => dispatch(refreshActions.stopRefreshPolling()),
  };
}

function mapStateToProps(state) {
  const { forms, profile, folders,ruleEngine } = state;
  return {
    forms,
    undeletedForms: forms.undeletedForms || [],
    paginatedForms: getPaginationSortedCatgories(state),
    viewType: forms.viewType,
    propertyRole: UserRoles[profile.userRole],
    sortBy: forms.sortBy,
    sortOrder: forms.sortOrder,
    actionType: forms.actionType,
    searchQuery: forms.searchQuery,
    count: getCount(state),
    selectedFolder: folders.selectedFolder,
    checkedForms: selectedFormsSelector(state),
    formIds: getFormIds(state),
    profile,
    folders,
    customNoticeModalState:forms.customNoticeModalState,
    formGenericRule:ruleEngine.formGenericRule,
  };
}

FormViewContainer.propTypes = {
  undeletedForms: PropTypes.array,
  viewType: PropTypes.string,
  propertyRole: PropTypes.number,
  publishUnpublish: PropTypes.func,
  createForm: PropTypes.func,
  setFormViewType: PropTypes.func,
  spinner: PropTypes.object,
  labels:PropTypes.func,
  actions:PropTypes.func,
  filterQuery:PropTypes.func,
  checkForm: PropTypes.func,
  uncheckForm: PropTypes.func,
  checkAllForms: PropTypes.func,
  uncheckAllForms: PropTypes.func,
  moveFormsToFolder: PropTypes.func,
  setSortOptions: PropTypes.func,
  getUndeletedForms: PropTypes.func,
  startRefreshPolling: PropTypes.func,
  forms: PropTypes.object,
  paginatedForms: PropTypes.object,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  actionType: PropTypes.string,
  searchParams: PropTypes.string,
  count: PropTypes.number,
  selectedFolder: PropTypes.object,
  checkedForms: PropTypes.object,
  formIds: PropTypes.array,
  profile: PropTypes.object,
  folders: PropTypes.object,
  isFormFoldersEnabled: PropTypes.bool,
  isPollingFormsRefresh: PropTypes.bool,
};

export default connect(mapStateToProps,mapDispatchToProps)(FormViewContainer);
